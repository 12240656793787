import React from 'react';
import BannerBackground from "../Assets/home-banner-background.png";
import Navbar from "./Navbar";
import { FiArrowRight } from "react-icons/fi";
import Logo from '../Assets/logo-transparent.png';

const Home = () => {
    const openDemoVideo = () => {
        window.open('https://www.youtube.com/watch?v=dejVMK5h3yY&t=2s', '_blank');
    }

    const logoStyle = {
        width: '45%',
        height: 'auto',
        padding: '14% 7%',
    };

    return (
        <div className='home-container' id='home'>
            <div className='home-banner-container'>
                <div className='home-bannerImage-container'>
                    <img src={BannerBackground} alt='' />
                </div>
                <div className='home-text-section'>
                    <h1 className='primary-heading'>
                        Our Company
                    </h1>
                    <p style={{ paddingBottom: '15px' }}>
                        Chick Pick specializes in poultry processing and utilizes cutting-edge technology to streamline poultry hatcheries.
                    </p>
                </div>
            </div>
            <div className='logo-overlay'>
                <img src={Logo} alt='Logo' style={logoStyle} />
            </div>
        </div>
    );
}

export default Home;
