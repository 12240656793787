import './App.css';
import Home from "./Components/Home"
import About from './Components/About'
import Work from './Components/Work'
import Testimonial from './Components/Testimonial'
import Contact from './Components/Contact'
import Footer from './Components/Footer'
import Design from "./Components/Design";
function App() {
  return (
    <div className="App">
      <section id='home'>
      <Home/>
      </section>
      <section id='contact'>
      <Contact/>
      </section>
      <Footer/>
    </div>
    
  );
}

export default App;
