import React from 'react'
import Logo from '../Assets/logo/horiz_c.png'
import { BsTwitter } from "react-icons/bs";
import { SiLinkedin } from "react-icons/si";
import { BsYoutube } from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa";

const Footer = () => {
  const linkedinURL = 'https://www.linkedin.com/company/chick-pick/about/?viewAsMember=true';
  const youtubeURL = 'https://www.youtube.com/watch?v=dejVMK5h3yY&t';
  const email = 'contact@chickpick.co';
  return (
<div className="footer-wrapper">
      <div className="footer-section-one">
        <div className="footer-logo-container">
          <img src={Logo} alt="" />
        </div>
      </div>
      <div className="footer-section-two">
        <div className="footer-section-columns">
          <a href={`mailto:${email}`}>{email}</a>
        </div>
        <div className="footer-section-columns">
          <span>1-506-461-0321</span>
        </div>
      </div>
    </div>
  )
}

export default Footer