import React from 'react'

const Contact = () => {

  const sendEmailToCompany = () => {
    const email = 'contact@chickpick.co'
    const subject = 'Product Inquiry';

    const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}`;
    window.open(mailtoLink);
  }

  return (
    <div className="contact-page-wrapper">
      <h1 className="primary-heading">Reach out if you want to </h1>
      <h1 className="primary-heading">upgrade your hatchery!</h1>
        <button className="secondary-button" onClick={sendEmailToCompany}>Email Us</button>
    </div>
  )
}

export default Contact